import React from "react";
import { Link, graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import Fade from "react-reveal/Fade";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import { FiPlus, FiMinus } from "react-icons/fi";

class FAQ extends React.Component {
  accordionOnChange = (expandedItems) => {};
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout
        HeaderExtension={
          <div className="header_extension no_bg">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={"25%"}>
                  <h1 className="primary heading_lg aligncenter">
                    <span>FAQ</span>
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
        }
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title="FAQ"
          keywords={[
            "Beauty brands, Personal care products, Personal care, Danielle Beauty, Danielle Mirrors, Therawell, Wanderflower, Studio Dry",
          ]}
          description="Got a question about one of our beauty brands? Find out more about all of our personal care products including Danielle Beauty, Danielle Mirrors, Therawell, Wanderflower, Studio Dry."
          image={null}
        />

        <div>
          <div className="pages-index pages-faq">
            <div className="container aligncenter">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <h5 className="banner-heading">Product related</h5>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Where is my UPC on the product?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Universal Product Code (UPC), also known as a barcode
                          is located on the back or bottom of your product.
                        </p>

                        <StaticImage
                          src={"../img/pages/faq/barcode.jpg"}
                          alt="Barcode"
                        />
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Where do I find the Item Number on a product?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The item numbers for all of our products are listed
                          directly above the barcode on the back or bottom of
                          the product.
                        </p>

                        <StaticImage
                          src={"../img/pages/faq/barcode_item.jpg"}
                          alt="Barcode"
                        />
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I have a comment or question about a specific product
                          – who do I contact?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Please feel free to reach out to our team at{" "}
                          <a href="mailto:contact@uppercanadasoap.com">
                            contact@uppercanadasoap.com!
                          </a>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I absolutely LOVE your product, where can I purchase
                          more of it?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Our products can be found in major department stores
                          across North America! And be sure to check out Amazon
                          for the product!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          How can I write a review on your product?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The best way to do this is through the retailer you
                          purchased it from! We love to read our retailer’s
                          reviews!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I have an issue with a product, who do I contact?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Please reach out to our customer service team with any
                          issues related to any of our products:
                          <br />
                          <a href="mailto:ucscustomerservice@uppercanadasoap.com">
                            ucscustomerservice@uppercanadasoap.com
                          </a>{" "}
                          or via phone at{" "}
                          <a href="tel:1-800-548-4097">1-800-548-4097</a>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="container aligncenter">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <h5 className="banner-heading">Order related</h5>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Your new website doesn’t allow me to place an order
                          for a product – how do I place an order?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          If you’re a returning retailer, please reach out to
                          our Customer Service Team{" "}
                          <a href="mailto:ucscustomerservice@uppercanadasoap.com">
                            ucscustomerservice@uppercanadasoap.com
                          </a>{" "}
                          and they would be more than willing to help!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I am an independent business looking to sell your
                          product – how do I place an order?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          If you have your rep number handy, please place your
                          order through ShopZio.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I have an issue with an order, who do I contact?
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Please reach out to our Customer Service team at{" "}
                          <a href="mailto:ucscustomerservice@uppercanadasoap.com">
                            ucscustomerservice@uppercanadasoap.com
                          </a>{" "}
                          or via phone at{" "}
                          <a href="tel:1-800-548-4097">1-800-548-4097</a> and
                          they can help you with your order.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="container aligncenter">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <h5 className="banner-heading">Other</h5>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10">
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          I want to work with you!
                          <AccordionItemState>
                            {({ expanded }) => (
                              <span>{expanded ? <FiMinus /> : <FiPlus />}</span>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Reach out to{" "}
                          <a href="mailto:contact@uppercanadasoap.com">
                            contact@uppercanadasoap.com
                          </a>{" "}
                          for potential business enquiries!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FAQ;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
